export default {
    public: {
        btn1: '登录',
        btn2: '注册',
        btn3: '查看更多',
        btn4: '查询',
        btn5: '了解详情',
        btn6: '立即注册',
        btn7: '忘记密码',
        btn9: '立即登录',
        btn10: '提交',
        btn11: '发送验证码',
        btn12: '搜索',
        placeholder: {
            placeholder1: '请输入快递单号',
            placeholder2: '必填项未填写'
        }
    },
    // 菜单
    menu: {
        menu1: '首页',
        menu2: '收費标准',
        menu3: '优惠活动',
        menu4: '集运服务',
        'menu4-1': '集运介绍',
        'menu4-2': '集运教学',
        'menu4-3': '集运路线',
        'menu4-4': '集运条款',
        'menu4-5': '自提点',
        menu5: '关于我们',
        'menu5-1': '企业概述',
        'menu5-2': '企业资讯',
        'menu5-3': '企业风采',
        'menu5-4': '商务合作',
        menu6: '特价商品',
        menu7: '遗失协议'
    },
    // 首页
    home: {
        // 搜索模块
        search: {
            lable1: '我要寄件',
            lable2: '运费时效查询'
        },
        // 幻灯片
        banner: {
            handleItem1: '专注台湾 · 集运服务',
            handleItem2: '货态追踪 · 智能系统',
            handleItem3: '强力清关 · 一站直达',
            handleItem4: '贴心服务 · 全天客服',
            handleItem5: '淘宝授权 · 入库无忧',
            message1: '七年专业跨境，专注台湾集运',
            message2: '包裹全程追踪，货态智能推送',
            message3: '高效清关渠道，包裹一站速达',
            message4: '全天专属客服，随时为您服务',
            message5: '账号绑定淘宝，网购入库无忧',
            messagezw1: '因为专注，所以专业',
            messagezw2: '更只能的集运系统',
            messagezw3: '安全   高效   快捷',
            messagezw4: '省心   放心   安心',
            messagezw5: '省时   省力   省心'
        },
        // 线路介绍
        introduce: {
            title: '集运 · 线路介绍',
            // 空运 除派将 和 泽宝外
            ky: {
                ky1: '空运专线，',
                ky2: '可寄食品，',
                ky3: '专线快至3天到台！',
                ky4: '适合寄贵重/紧急物品'
            },
            // 海快
            hk: {
                hk1: '海运快船，',
                hk2: '不分普/特货，',
                hk3: '专线快至5天到台！',
                hk4: '高性价比推荐'
            },
            // 海慢
            hm: {
                hm1: '海运慢船，',
                hm2: '支持重量100KG+,',
                hm3: '约10-12天到台!',
                hm4: '适合承运大件物品'
            },
            // 派将店铺显示的
            pj: {
                ky: {
                    ky1: '派送专线',
                    ky2: '包裹派送到府',
                    ky3: '参考时效3-5天到港',
                    ky4: '适合方便在府取货的顾客'
                },
                // 海快
                hk: {
                    hk1: '自提专线',
                    hk2: '包裹送至自提点',
                    hk3: '参考时效3-5天到港',
                    hk4: '适合方便自取点取货的顾客'
                },
                // 海慢
                hm: {
                    hm1: '顺丰专线',
                    hm2: '可选派送或自取',
                    hm3: '参考时效2-3天到港',
                    hm4: '适合寄贵重/紧急物品的顾客'
                }
            },
            // 泽宝店铺显示的
            zb: {
                ky: {
                    ky1: '空运专线',
                    ky2: '迅捷、安全，效率最高',
                    ky3: '适合贵重/紧急物品的运输'
                },
                // 海快
                hk: {
                    hk1: '海运专线',
                    hk2: '运输量大，运费低，航道四通八达',
                    hk3: '适合杂货和对时效没有要求的运输'
                },
                // 海慢
                hm: {
                    hm1: '铁路专线',
                    hm2: '可运类型多，运输量最大',
                    hm3: '适合长途大量货物的运输'
                }
            },
            // 顺通店铺显示
            st: {
                ky: {
                    ky1: '空运专线',
                    ky2: '可邮寄食品',
                    ky3: '适合小件物品和贵重物品',
                    ky4: '快至3天送达'
                },
                // 海快
                hk: {
                    hk1: '海快专线',
                    hk2: '最快3天送达',
                    hk3: '不分普特货，性价比之王',
                    hk4: '不可邮寄食品'
                },
                // 海慢
                hm: {
                    hm1: '海运专线',
                    hm2: '适合家具',
                    hm3: '大件物品，包税包清关',
                    hm4: '低至3.5元/公斤'
                }
            },
            // 辰风
            cf: {
                ky: {
                    ky1: '空运专线',
                    ky2: '迅捷、安全，效率高'
                },
                // 海快
                hk: {
                    hk1: '海卡专线',
                    hk2: '大货首选',
                    hk3: '时效稳定，双清包税',
                    hk4: '可邮寄北爱尔兰地区'
                },
                // 海慢
                hm: {
                    hm1: '海运专线',
                    hm2: '适合家具等大件物品',
                    hm3: '双清包税，可邮寄北爱尔兰地区'
                }
            }
        },
        // 服务优势
        service: {
            title: '集运 · 服务优势',
            lable1: '强力清关',
            lable2: '专线直达',
            lable3: '丢货包赔',
            lable4: '专注台湾',
            lable5: '全天客服',
            lable6: '货态跟踪',
            lable7: '大型仓库',
            lable8: '免费仓储',
            lable9: '专注集运'
        },
        // 集运流程
        processSteps: {
            title: '集运流程 · 简单就好'
        },
        style: {
            title: '集运风采'
        }
    },
    // 页尾
    footer: {
        phone: '联系方式：（大陆TEL）18319011167',
        mail: '企业邮箱：fm18319011167@gmail.com',
        address: '总部地址：深圳市宝安区福永街道白石厦社区广深路福永段97号8栋301',
        qrCode: {
            title: '关注我们',
            wx: '微信公众号',
            line: 'Line账号'
        },
        menu: {
            list1: {
                menu1: '集运服务',
                menu2: '集运介绍',
                menu3: '集运教学',
                menu4: '线路介绍',
                menu5: '集运条款'
            },
            list2: {
                menu1: '集运查询',
                menu2: '运单查询',
                menu3: '运费估算',
                menu4: '仓库地址',
                menu5: '禁运品类'
            },
            list3: {
                menu1: '关于我们',
                menu2: '企业概述',
                menu3: '企业资讯',
                menu4: '企业风采',
                menu5: '商务合作'
            },
            list4: {
                menu1: '会员中心',
                menu2: '会员登录',
                menu3: '会员注册',
                menu4: '关于退货',
                menu5: '隐私政策'
            }
        },
        // 第三方购物平台
        friendship: {
            message: '友情链接',
            menu1: '淘宝',
            menu2: '阿里巴巴',
            menu3: '天猫',
            menu4: '京东',
            menu5: '苏宁易购',
            menu6: '亚马逊'
        },
        record: {
            message: '技術支援'
        },
        rules: {
            menu1: '免责声明',
            menu2: '隐私保护声明',
            menu3: '违禁品说明',
            menu4: '条款与细则'
        }
    },
    // 运费试算
    standard: {
        btn1: '开始估算',
        lable1: '税金',
        lable2: '不包税',
        lable3: '包税',
        lable4: '线路',
        lable5: '选择线路',
        title: [
            '收货地区',
            '货物类型',
            '运费试算',
            '包裹体积',
            '预估费用'
        ],
        title2: [
            '计费方式',
            '渠道限制',
            '集运赔付',
            '禁运品',
            '其它'
        ],
        table1: [
            '仓库',
            '重量',
            '首重',
            '续重'
        ],
        table2: [
            '仓库',
            '金额',
            '详细'
        ],
        table3: [
            '仓库',
            '承运商'
        ],
        form: {
            lable2: '包裹重量',
            placeholder2: '长(CM)',
            placeholder3: '宽(CM)',
            placeholder4: '高(CM)',
            placeholder1: '如：0.3Kg，则輸入0.3',
            message: '请输入包裹重量',
            message1: '长宽高重量不可小于0'
        },
        // 单号查询
        orderQuery: {
            title: '快递单号',
            title1: '单号',
            tebleTitle: {
                title: [
                    '时间',
                    '状态',
                    '地点和跟踪进度'
                ]
            }
        }
    },
    // 集运模块
    transport: {
        // 自提点
        SelfPromotion: {
            lable1: '超商类型',
            lable2: '超商地址',
            lable3: '超商名称',
            lable4: '是否启用中',
            lable5: '超商电话',
            lable6: '超商地址',
            lable7: '最大限重',
            lable8: '营业时间'
        }
    },
    // 关于我们模块
    about: {
        // 企业概述
        enterpriseOverview: {
            lable: [
                '飞马（深圳）国际运通有限公司（简称台集运），创立于2017年5月，是一家跨境物流服务公司，专为海外华人华侨、留学生、跨境电商提供跨境物流的全方位服务解决方案，至今已服务过超100w+客户，是跨境物流服务行业的开拓者和领航企业。',
                '公司总部',
                '深圳市宝安区永福街道白石厦社区广深路福永段97号8栋301',
                '仓库地址',
                '广东省东莞市洪梅镇尧均村均安路4号808仓（181227）',
                '联系电话',
                '大陆TEL: 18319011167',
                '邮箱',
                'fm18319011167@gmail.com'
            ]
        },
        // 企业资讯
        businessConsulting: {
            title: '列表',
            title2: '资讯详情'
        },
        // 企业风采
        enterpriseStyle: {},
        // 商务合作
        businessCooperation: {
        },
        // 关于退货
        returnGoods: {
            title: '关于退货'
        }
    },
    // 协议 和 隐私
    agreement: {
    },
    // 登录页
    login: {
        lable1: '链接全球，为您提供360度全方位物流服务',
        lable2: '密码登录',
        lable3: '注册',
        // 菜单
        menu: {
            lable: [
                '官网',
                '淘宝'
            ]
        },
        // 登录表单
        signin: {
            lable1: '记住密码',
            placeholder: [
                '请输入手机号/账号',
                '请输入密码',
                '请输入短信验证码'
            ]
        },
        // 注册表单
        register: {
            massage: '请先同意集运条款',
            massage2: '请先输入手机号',
            message3: '发送成功，请查收',
            massage4: '两次密码不一致',
            placeholder: [
                '请输入手机号（必填）',
                '请设置密码（必填）',
                '请再次输入密码（必填）'
            ],
            label: [
                '我已同意集运',
                '《承运条款》'
            ]
        },
        // 忘记密码
        forgotPassword: {
            message: '修改成功',
            placeholder: [
                '请输入您的手机号',
                '请输入验证码',
                '请输入新密码'
            ],
            btn: '返回登陆'
        },
        // 注册成功
        registerSuccess: {
            lable1: '注册成功',
            lable2: '手机号码：',
            lable3: '密码：',
            lable4: '温馨提示：请截图保存或笔记记录好您的账号信息！^_^以免忘记哦~<',
            lable6: '去登录',
            lable7: '登录账号'
        }
    },
    commodity: {
        lable1: '月销',
        lable2: '特价商品',
        lable3: '上一页',
        lable4: '下一页',
        pl1: '请输入商品搜索'
    },
    text1: '推荐人会员ID',
    text2: '请输入图形验证码',
    text3: '运输方式',
    text146: '開始估算',
    text147: '包裹尺寸',
    text148: '包裹重量',
    text149: '计费方式',
    text150: '集運賠付',
    text151: '禁运品',
    text152: '百万用户的放心选择！'
};
