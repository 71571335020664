export default {
    public: {
        btn1: '登入',
        btn2: '註冊',
        btn3: '查看更多',
        btn4: '査詢',
        btn5: '瞭解詳情',
        btn6: '立即註冊',
        btn7: '忘記密碼',
        btn9: '立即登入',
        btn10: '提交',
        btn11: '發送驗證碼',
        btn12: '搜索',
        placeholder: {
            placeholder1: '請輸入快遞單號',
            placeholder2: '必填項未填寫'
        }
    },
    // 菜单
    menu: {
        menu1: '首頁',
        menu2: '收費標準',
        menu3: '優惠活動',
        menu4: '集運服務',
        'menu4-1': '集運介紹',
        'menu4-2': '集運教學',
        'menu4-3': '集運線路',
        'menu4-4': '集運條款',
        'menu4-5': '自提點',
        menu5: '關於我們',
        'menu5-1': '企業概述',
        'menu5-2': '企業資訊',
        'menu5-3': '企業風采',
        'menu5-4': '商務合作',
        menu6: '特價商品',
        menu7: '遺失協定'
    },
    // 首页
    home: {
        // 搜索模块
        search: {
            lable1: '我要寄件',
            lable2: '運費時效査詢'
        },
        // 幻灯片
        banner: {
            handleItem1: '專注台灣 · 集運服務',
            handleItem2: '貨態追蹤 · 智能系統',
            handleItem3: '強力清關 · 一站直達',
            handleItem4: '貼心服務 · 全天客服',
            handleItem5: '淘寶授權 · 入庫無憂',
            message1: '七年專業跨境，專注台灣集運',
            message2: '包裹全程追蹤，貨態智能推送',
            message3: '高效清關渠道，包裹一站速達',
            message4: '全天專屬客服，隨時為您服務',
            message5: '賬號綁定淘寶，網購入庫無憂',
            messagezw1: '因為專注，所以專業',
            messagezw2: '更智能的集運系統',
            messagezw3: '安全   高效   快捷',
            messagezw4: '省心  放心  安心',
            messagezw5: '省時 省力 省心'
        },
        // 线路介绍
        introduce: {
            title: '集運 · 線路介紹',
            // 空运 除派将 和 泽宝外
            ky: {
                ky1: '空運專線，',
                ky2: '可寄食品，',
                ky3: '專線快至3天到台！',
                ky4: '適合寄貴重/緊急物品'
            },
            // 海快
            hk: {
                hk1: '海運快船，',
                hk2: '不分普/特貨，',
                hk3: '專線快至5天到台！',
                hk4: '高性價比推薦'
            },
            // 海慢
            hm: {
                hm1: '海運慢船，',
                hm2: '支持重量100KG+,',
                hm3: '約10-12天到台!',
                hm4: '適合承運大件物品'
            },
            // 派将店铺显示的
            pj: {
                ky: {
                    ky1: '派送專線',
                    ky2: '包裹派送到府',
                    ky3: '參考時效3-5天到港',
                    ky4: '適合方便在府取貨的顧客'
                },
                // 海快
                hk: {
                    hk1: '自提專線',
                    hk2: '包裹送至自提點',
                    hk3: '參考時效3-5天到港',
                    hk4: '適合方便自取點取貨的顧客'
                },
                // 海慢
                hm: {
                    hm1: '順豐專線',
                    hm2: '可選派送或自取',
                    hm3: '參考時效2-3天到港',
                    hm4: '適合寄貴重/緊急物品的顧客'
                }
            },
            // 泽宝店铺显示的
            zb: {
                ky: {
                    ky1: '空運專線',
                    ky2: '迅捷、安全，效率最高',
                    ky3: '適合貴重/緊急物品的运输'
                },
                // 海快
                hk: {
                    hk1: '海运專線',
                    hk2: '运输量大，运费低，航道四通八达',
                    hk3: '適合杂货和对时效没有要求的运输'
                },
                // 海慢
                hm: {
                    hm1: '铁路專線',
                    hm2: '可运类型多，运输量最大',
                    hm3: '適合长途大量货物的运输'
                }
            },
            // 顺通店铺显示
            st: {
                ky: {
                    ky1: '空運專線',
                    ky2: '可郵寄食品',
                    ky3: '適合小件物品和貴重物品',
                    ky4: '快至3天送達'
                },
                // 海快
                hk: {
                    hk1: '海快專線',
                    hk2: '最快3天送達',
                    hk3: '不分普特貨，性價比之王',
                    hk4: '不可郵寄食品'
                },
                // 海慢
                hm: {
                    hm1: '海運專線',
                    hm2: '適合家具',
                    hm3: '大件物品，包稅包清關',
                    hm4: '低至3.5元/公斤'
                }
            },
            // 辰风
            cf: {
                ky: {
                    ky1: '空運專線',
                    ky2: '迅捷、安全，效率高'
                },
                // 海快
                hk: {
                    hk1: '海卡專線',
                    hk2: '大貨首選',
                    hk3: '時效穩定，雙清包稅',
                    hk4: '可郵寄北愛爾蘭地區'
                },
                // 海慢
                hm: {
                    hm1: '海運專線',
                    hm2: '適合傢俱等大件物品',
                    hm3: '雙清包稅，可郵寄北愛爾蘭地區'
                }
            }
        },
        // 服务优势
        service: {
            title: '集運 · 服務優勢',
            lable1: '強力清關',
            lable2: '專線直達',
            lable3: '丟貨包賠',
            lable4: '專注台灣',
            lable5: '全天客服',
            lable6: '貨態跟蹤',
            lable7: '大型倉庫',
            lable8: '免費倉儲',
            lable9: '專注集運'
        },
        // 集运流程
        processSteps: {
            title: '集運流程 · 簡單就好'
        },
        style: {
            title: '集運風采'
        }
    },
    // 页尾
    footer: {
        phone: '聯繫方式：(大陸TEL)18319011167',
        mail: '企業郵箱：fm18319011167@gmail.com',
        address: '總部地址：深圳市宝安区福永街道白石厦社区广深路福永段97號8栋301',
        qrCode: {
            title: '關注我們',
            wx: '微信公衆號',
            line: 'line賬號'
        },
        menu: {
            list1: {
                menu1: '集運服務',
                menu2: '集運介紹',
                menu3: '集運教學',
                menu4: '線路介紹',
                menu5: '集運條款'
            },
            list2: {
                menu1: '集運查詢',
                menu2: '運單查詢',
                menu3: '運費估算',
                menu4: '倉庫地址',
                menu5: '禁運品類'
            },
            list3: {
                menu1: '關於我們',
                menu2: '企業概述',
                menu3: '企業資訊',
                menu4: '企業風采',
                menu5: '商務合作'
            },
            list4: {
                menu1: '會員中心',
                menu2: '會員登入',
                menu3: '會員注冊',
                menu4: '關於退貨',
                menu5: '隱私政策'
            }
        },
        // 第三方购物平台
        friendship: {
            message: '友情鏈接',
            menu1: '淘寶',
            menu2: '阿里巴巴',
            menu3: '天貓',
            menu4: '京東',
            menu5: '蘇寧易購',
            menu6: '亞馬遜'
        },
        record: {
            message: '技術支援'
        },
        rules: {
            menu1: '免責聲明',
            menu2: '隱私保護聲明',
            menu3: '違禁品說明',
            menu4: '條款與細則'
        }
    },
    // 运费试算
    standard: {
        btn1: '開始估算',
        lable1: '税金',
        lable2: '不包税',
        lable3: '包税',
        lable4: '線路',
        lable5: '選擇線路',
        title: [
            '收貨地區',
            '貨物類型',
            '運費試算',
            '包裹體積',
            '預估費用'
        ],
        title2: [
            '計費方式',
            '渠道限制',
            '集運賠付',
            '禁運品',
            '其它'
        ],
        table1: [
            '倉庫',
            '重量',
            '首重',
            '續重'
        ],
        table2: [
            '倉庫',
            '金額',
            '詳細'
        ],
        table3: [
            '仓库',
            '承運商'
        ],
        form: {
            lable2: '包裹重量',
            placeholder2: '長(CM)',
            placeholder3: '寬(CM)',
            placeholder4: '高(CM)',
            placeholder1: '如：0.3Kg，則輸入0.3',
            message: '請輸入包裹重量',
            message1: '長寬高重量不可小於0'
        },
        // 单号查询
        orderQuery: {
            title: '快遞單號',
            title1: '單號',
            tebleTitle: {
                title: [
                    '時間',
                    '狀態',
                    '地點和跟蹤進度'
                ]
            }
        }
    },
    // 集运模块
    transport: {
        // 自提点
        SelfPromotion: {
            lable1: '超商類型',
            lable2: '超商地址',
            lable3: '超商名稱',
            lable4: '是否啟用中',
            lable5: '超商電話',
            lable6: '超商地址',
            lable7: '最大限重',
            lable8: '營業時間'
        }
    },
    // 关于我们模块
    about: {
        // 企业概述
        enterpriseOverview: {
            lable: [
                '飛馬(深圳)國際運通有限公司（簡稱台集運），創立於2017年5月，是一家跨境物流服務公司，專為海外華人華僑、留學生、跨境電商提供跨境物流的全方位服務解決方案，至今已服務過超100w+客戶，是跨境物流服務行業的開拓者和領航企業。',
                '公司總部',
                '深圳市寶安區永福街道白石廈社區廣深路福永段97號8棟301',
                '倉庫地址',
                '廣東省東莞市洪梅鎮堯均村均安路4號808倉（181227）',
                '聯繫電話',
                '大陸TEL: 18319011167',
                '郵箱',
                'fm18319011167@gmail.com'
            ]
        },
        // 企业资讯
        businessConsulting: {
            title: '清單',
            title2: '資訊詳情'
        },
        // 企业风采
        enterpriseStyle: {},
        // 商务合作
        businessCooperation: {
        },
        // 关于退货
        returnGoods: {
            title: '關於退貨'
        }
    },
    // 协议 和 隐私
    agreement: {
    },
    // 登录页
    login: {
        lable1: '連結全球，為您提供360度全方位物流服務',
        lable2: '密碼登入',
        lable3: '註冊',
        // 菜单
        menu: {
            lable: [
                '官網',
                '淘寶'
            ]
        },
        // 登录表单
        signin: {
            lable1: '記住密碼',
            placeholder: [
                '請輸入手機號/帳號',
                '請輸入密碼',
                '請輸入短信驗證碼'
            ]
        },
        // 註冊表单
        register: {
            massage: '請先同意集運條款',
            massage2: '請先輸入手機號',
            message3: '發送成功，請查收',
            massage4: '两次密碼不一致',
            placeholder: [
                '請輸入手機號（必填）',
                '請設置密碼（必填）',
                '請再次輸入密碼（必填）'
            ],
            label: [
                '我已同意集運',
                '《承運條款》'
            ]
        },
        // 忘记密码
        forgotPassword: {
            message: '修改成功',
            placeholder: [
                '請輸入您的手機號',
                '請輸入驗證碼',
                '請輸入新密碼'
            ],
            btn: '返回登陸'
        },
        // 註冊成功
        registerSuccess: {
            lable1: '註冊成功',
            lable2: '手機號碼：',
            lable3: '密碼：',
            lable4: '溫馨提示：請截圖保存或筆記記錄好您的帳號資訊！^_^以免忘記哦~<',
            lable6: '去登入',
            lable7: '登入帳號'
        }
    },
    commodity: {
        lable1: '月銷',
        lable2: '特價商品',
        lable3: '上一頁',
        lable4: '下一頁',
        pl1: '請輸入商品搜索'
    },
    text1: '推薦人會員ID',
    text2: '請輸入圖形驗證碼',
    text3: '運輸方式',
    text146: '开始估算',
    text147: '包裹尺寸',
    text148: '包裹重量',
    text149: '計費方式',
    text150: '集運賠付',
    text151: '禁運品',
    text152: '百萬用户的放心選擇！'

};
